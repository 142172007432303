.resumeContainer {
  display: flex;
  flex-direction: column;

  .resumeImg {
      width: 75vw;
    @media only screen and (max-width: 835px) {
      width: 95vw;
    }
      align-self: center;
    margin-top: 54px;
  }

  .downloadContainer {
    position: relative;
    z-index: 3;
    bottom: 24px;
    margin: auto;

    &:hover {
      cursor: pointer;
    }

    span {
      padding: 22px 14px;
      background-color: #48a1e6;
      border-radius: 45px;

      a {
        text-decoration: none;
        color: #ffffff;
        font-family: 'Open Sans', sans-serif;
        font-size: 1.3vw;
      }
    }
  }

  & + .divider {
    margin-top: 36px;
  }
}