@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.studiosContainer {
  padding-top: 54px;

  .divider {

  }
}

.sectionDescription {
  margin-bottom: 24px;
}

.postsContainer {
  margin-top: 32px;
  span {
    display: flex;
    justify-content: center;
    p {
      margin-left: 22px;
      font-size: 16px;
      line-height: 5vw;
    }
  }

  .postSection {
    line-height: 0;
    column-count: 4;
    column-gap: 0;
    margin-right: 48px;
    margin-left: 48px;

    @media only screen and (max-width: 750px) {
        column-count: 2;
    }

    @media only screen and (min-width: 750px) and (max-width: 945px) {
      column-count: 3;
    }


    .photoContainer {
      width: 100%;
      height: 100%;
      position: relative;

      img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: auto;
        display: inline-block;
      }

      &:hover {
        .overlay {
          opacity: 1;
          bottom: 0;
          height: 100%;
        }

        img {
          filter: blur(12px);
        }
      }

      .overlay {
        position: absolute;
        bottom: 100%;
        left: 0;
        right: 0;
        overflow: hidden;
        height: 0;
        width: 100%;
        transition: .5s ease;
        background-color: rgba(219, 219, 219, 0.3);
        box-shadow: -1px 0 20px -6px #dbdbdb;

        .imgInfo {
          color: #000000;
          font-size: 16px;
          font-family: 'Open Sans', sans-serif;
          line-height: 1.25;
          position: absolute;
          width: 100%;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          text-align: center;
        }
      }
    }








  }
  .loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 48px;
    height: 48px;
    animation: spin 2s linear infinite;
  }
}