.weatherContainer {
  width: 100vw;
  height: 100vh;
  background-color: #f5a623;
  display: flex;
  flex-direction: column;
  align-items: center;

  .weather-title {
    font-family: 'OpenSans-Light', sans-serif;
    font-weight: 300;
    font-size: 72px;
    margin-top: 24px;
    color: #ffffff;
    border-bottom: 2px solid #ffffff;

    #current {
      margin-right: 72px;
    }
    #forecast {
      margin-left: 72px;
    }
  }

  .weather-input__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;

    .weather-input__prompt {
      font-family: 'Open Sans', sans-serif;
      font-size: 32px;
      color: #ffff;
    }

    .weather-input__field[type='text'] {
      width: 256px;
      height: 64px;
      font-size: 30px;
      border: 0;
      border-radius: 8px;
      margin: 24px 0 24px;
      text-align: center;
      &::placeholder {
        opacity: 0.8;
        color: #fad291;
      }
    }

    .weather-input__helper-text {
      font-family: 'OpenSans-Light', sans-serif;
      font-size: 16px;
      color: #ffff;
      font-weight: 700;
    }
  }
}