.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 24px;
  margin-left: 24px;

  .title {
    font-family: 'OpenSans-Light', sans-serif;
    font-size: 36px;
    padding-bottom: 5px;
  }
}
