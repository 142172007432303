.projectsContainer {
  padding-top: 54px;
  .divider {
    margin-top: 0;
  }
  .energy-viz {
    .project__headings {
      align-items: end;
      //&:hover {
      //  h1.project__name {
      //    color: #009aff;
      //  }
      //}

      h1.em-dash {
        color: #009aff;
        position: relative;
        top: -38px;
        left: -48px;
      }
    }

    .project__image {
      background-color: #009aff;
      img {
        border-radius: 10px;
        width: 175px;
        height: auto;
      }
      //&:hover {
      //  img {
      //    transform: scale(1.2)
      //  }
      //}
    }
  }

  .current-weather {
    h1 {
      //&:hover {
      //  &.project__name {
      //    color: #f5a623;
      //  }
      //}
      &.em-dash {
        color: #f5a623;
        margin: 0;
      }
    }

    .project__image {
      background-color: #f5a623;
      img {
        width: 300px;
      }
    }
  }

  .eventr {
    h1 {
      //&:hover {
      //  &.project__name {
      //    color: #0d5b5a;
      //  }
      //}
      &.em-dash {
        color: #0d5b5a;
        margin: 0;
      }
    }

    .project__image {
      background-color: #0d5b5a;
      img {
        width: 375px;
      }
    }
  }
}
