.nav-container {
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 100;
  font-family: 'Open Sans', sans-serif;
  height: 54px;

  &.fixedScroll {
    background-color: #000000;
    transition: background-color 0.5s ease;
    box-shadow: 0 3px 20px #000000;
  }
}
.navigation {
  display: flex;
  justify-content: flex-end;
  margin-right: 3vw;
  .nav-item {
    color: #fff;
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }
}