@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.Landing__container {
  width: 100%;
  height: auto;
  position: relative;

  .landing_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
  }

  .landing__headings {
    position: absolute;
    top: 15%;
    left: 41%;
    color: white;
    display: flex;
    flex-direction: column;
    text-align: center;

    h1 {
      font-family: 'Open Sans', sans-serif;
      font-weight: 300;
      font-size: 3.35vw;
      margin: 0;
    }

    h4 {
      margin: 0;
      font-family: 'Lato', sans-serif;
      font-size: 1.13vw;
    }
  }

  .arrow__down {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 3vw solid transparent;
    border-right: 3vw solid transparent;
    border-top: 3vw solid #f00;
    bottom: 17%;
    left: 48%;
    animation: fadein 2s;

    &:hover {
      border-top-color: #b10000;
      cursor: pointer;
    }

  }

}
