.divider {
  margin-top: 64px;
  transform: scale(0.75);
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.aboutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 54px;

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .title {
    margin-bottom: 1rem;
  }

  .sectionDescription {
    width: calc(100vw - 365px);
    @media only screen and (max-width: 1024px) {
      width: calc(100vw - 200px);
    }
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    line-height: 1.75;

    .About-info {
      text-align: left;

      &:nth-child(2) {
        margin-bottom: 0;
      }

      .About-intern {
        margin: 0;
        font-weight: 600;
      }
    }

    .social__icons {
      display: flex;
      justify-content: space-between;

      svg:hover {
        cursor: pointer;
      }

      #linkedIn {
        path {
          fill: #0077B5;
        }

        &:hover {
          opacity: 0.8;
        }
      }

      #github {
        path {
          fill: #4500a9;
        }

        &:hover {
          opacity: 0.8;
        }
      }

      #instagram {
        width: 1em;
        border-radius: 10px;
        background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%),
        radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
        radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
        radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),
        radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
        radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),
        radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
        linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);

        path {
          fill: white;
        }

        &:hover {
          opacity: 0.8;
        }
      }

      #spotify {
        path {
          fill: #1DB954;
        }

        &:hover {
          opacity: 0.8;
        }
      }

      #email {
        path {
          fill: #656363;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
