.projects {
  margin-bottom: 72px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .description__container {
    display: flex;
    flex-direction: column;
    width: 600px;

    .project__headings {
      display: flex;
      flex-direction: row;

      h1 {
        &:hover {
          //cursor: pointer;
        }

        &.em-dash {
          margin: 0;
        }

        &.project__name {
          text-align: left;
          margin-top: 0;

          a {
          text-decoration: inherit;
          color: inherit;
          }
        }
      }
    }

    .project__description {
      text-align: left;
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 24px;
    }
  }

  .project__image {
    position: relative;
    width: 500px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;

    &:hover {
      //cursor: pointer;
      overflow: hidden;
      img {
        transform: scale(1.35);
      }
    }

    img {
      width: 175px; // subject to change
      height: auto;
      transition: transform .2s;
      postition: relative;
    }
  }
}
